<template>
      <div class="B-main white OutGlow Radius">
        <div class="B-user-main">
          <div class="B-display">
            <div
              class="display ColorTheme-S--border"
              style="background-image: url(images/display.svg)"
            ></div>
          </div>
          <div class="B-detail">
            <p>
              <span class="ColorTheme--text">Name - Surname</span>: {{formModel.FullName}}
            </p>
            <p>
              <span class="ColorTheme--text">Company</span>: The Prodigy Group
            </p>
            <p>
              <span class="ColorTheme--text">Position</span>: {{formModel.JobPosition}}
            </p>
          </div>
          <div class="B-btn">
            <v-btn text class="ColorTheme-S white--text center btn-size" @click="Logout()">Back</v-btn>
          </div>
        </div>
      </div>

</template>
<script>
import feathersClient from "../plugins/feathers-client";

export default {
  data: () => ({
    service: "user",
    objectName: "พนักงาน",
    defaultValue: {
      ImageUrl: "/img/user.png"
    },
    //--end config
    formModel: {},
    dialog: false,
    loading: false
  }),
  props: ["Id"],
  components: {
    // ResetPassword
  },
 
  async mounted() {
    this.renderUI();
  },
  methods: {
    async renderUI() {
      try {
        const { user } = await feathersClient.get("authentication");
        let inDTO = await feathersClient.service("user").find({ query: { Id: user.Id } });
        this.formModel = Object.assign({}, inDTO.data[0]);
      } catch (err) {
        //console.log(err);
        alert("ไม่สามารถต่อ server ได้");
      }

    },
    Logout() {
      feathersClient.logout();
      this.$router.push("/login");
    },
    
  }
};
</script>

<style scoped>
.B-main {
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}
.B-login-main {
  width: 100%;
}
.B-btn {
  margin-top: 5px;
}
.B-display {
  margin-bottom: 20px;
}
.B-display > .display {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background: center center;
  background-size: 100% auto;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  overflow: hidden;
  border: 5px solid;
}
.B-detail {
  margin-bottom: 15px;
}
.B-detail > p {
  margin-bottom: 10px;
}
.B-detail > p span {
  margin-right: 5px;
  font-weight: 500;
}

/* ---------------------------------------------------**** Tablet Portrait ****--------------------------------------------------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
/* ---------------------------------------------------**** Mobile ****--------------------------------------------------- */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .B-main {
    width: 90%;
  }
  .b-logo > img {
    width: 80%;
  }
}
</style>
